import { useContext, useEffect, useRef } from 'react'
import { Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { LayoutProvider, MasterInit } from 'ufinet-web-components'
import { AuthContext, authService } from 'ufinet-web-functions'

const App = () => {
	const { setAuthFrame } = useContext(AuthContext)

	const authFrameRef = useRef<HTMLIFrameElement>(null)

	useEffect(() => {
		authFrameRef.current && setAuthFrame(authFrameRef.current)
	}, [])

	return (
		<LayoutProvider>
			<Outlet />
			<MasterInit />
			<ToastContainer />
			<iframe ref={authFrameRef} hidden={true} title="Iframe login SSO" src={authService.getSSOLoginUrl()} />
		</LayoutProvider>
	)
}

export { App }
